var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card-code',{attrs:{"title":"Form Tambah Arsip"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Subjek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subjek","label-for":"Subjek"}},[_c('b-form-input',{attrs:{"id":"Subjek","placeholder":"Tulis Subjek"},model:{value:(_vm.Subjek),callback:function ($$v) {_vm.Subjek=$$v},expression:"Subjek"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"No. Surat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"No. Surat","label-for":"No. Surat"}},[_c('b-form-input',{attrs:{"id":"No. Surat","placeholder":"Tulis No. Surat"},model:{value:(_vm.NoSurat),callback:function ($$v) {_vm.NoSurat=$$v},expression:"NoSurat"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lokasi","label-for":"Lokasi"}},[_c('b-form-input',{attrs:{"id":"Lokasi","placeholder":"Tulis Lokasi"},model:{value:(_vm.Lokasi),callback:function ($$v) {_vm.Lokasi=$$v},expression:"Lokasi"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Kategori","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kategori","label-for":"Kategori"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Pilih Kategori","options":_vm.optionsKategori},model:{value:(_vm.Kategori),callback:function ($$v) {_vm.Kategori=$$v},expression:"Kategori"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Waktu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Waktu","label-for":"Waktu"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"Waktu","locale":"id"},model:{value:(_vm.Waktu),callback:function ($$v) {_vm.Waktu=$$v},expression:"Waktu"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Deadline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.Waktu === null ),expression:"Waktu === null "}],staticClass:"warning-right"},[_vm._v(" Pilih Waktu terlebih dahulu ")]),_c('b-form-group',{attrs:{"label":"Deadline","label-for":"Deadline"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"Deadline","min":_vm.Waktu,"disabled":_vm.Waktu == null,"locale":"id"},model:{value:(_vm.Deadline),callback:function ($$v) {_vm.Deadline=$$v},expression:"Deadline"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Surat Diterima","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Surat Diterima","label-for":"Surat Diterima"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"Surat Diterima","locale":"id"},model:{value:(_vm.ReceivedDate),callback:function ($$v) {_vm.ReceivedDate=$$v},expression:"ReceivedDate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"Keterangan"}},[_c('b-form-input',{attrs:{"id":"Keterangan","placeholder":"Tulis Keterangan"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File Arsip","label-for":"FileArsip"}},[_c('b-form-file',{ref:"file",attrs:{"id":"FileArsip","type":"file","placeholder":"Tambah File Arsip","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChange},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"FileSurat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Tambah Tag"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{staticClass:"mt-1",attrs:{"name":"Deskripsi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Deskripsi")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Tulis Deskripsi","rows":"3"},model:{value:(_vm.Deskripsi),callback:function ($$v) {_vm.Deskripsi=$$v},expression:"Deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Simpan")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }