<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Tambah Arsip">
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Subjek"
            rules="required"
          >
            <b-form-group
              label="Subjek"
              label-for="Subjek"
            >
              <b-form-input
                id="Subjek"
                v-model="Subjek"
                placeholder="Tulis Subjek"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Surat"
            rules="required"
          >
            <b-form-group
              label="No. Surat"
              label-for="No. Surat"
            >
              <b-form-input
                id="No. Surat"
                v-model="NoSurat"
                placeholder="Tulis No. Surat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Lokasi"
            rules="required"
          >
            <b-form-group
              label="Lokasi"
              label-for="Lokasi"
            >
              <b-form-input
                id="Lokasi"
                v-model="Lokasi"
                placeholder="Tulis Lokasi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <v-select
                v-model="Kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Pilih Kategori"
                :options="optionsKategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Waktu"
            rules="required"
          >
            <b-form-group
              label="Waktu"
              label-for="Waktu"
            >
              <b-form-datepicker
                id="Waktu"
                v-model="Waktu"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Deadline"
            rules="required"
          >
            <span
              v-show="Waktu === null "
              class="warning-right"
            > Pilih Waktu terlebih dahulu </span>
            <b-form-group
              label="Deadline"
              label-for="Deadline"
            >
              <b-form-datepicker
                id="Deadline"
                v-model="Deadline"
                :min="Waktu"
                :disabled="Waktu == null"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Surat Diterima"
            rules="required"
          >
            <b-form-group
              label="Surat Diterima"
              label-for="Surat Diterima"
            >
              <b-form-datepicker
                id="Surat Diterima"
                v-model="ReceivedDate"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Keterangan"
          >
            <b-form-group
              label="Keterangan"
              label-for="Keterangan"
            >
              <b-form-input
                id="Keterangan"
                v-model="note"
                placeholder="Tulis Keterangan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="File"
            rules="required"
          >
            <b-form-group
              label="File Arsip"
              label-for="FileArsip"
            >
              <b-form-file
                id="FileArsip"
                ref="file"
                v-model="files"
                type="file"
                placeholder="Tambah File Arsip"
                drop-placeholder="Drop file here..."
                multiple="multiple"
                @change="fileChange"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Deskripsi"
            class="mt-1"
          >
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              v-model="Deskripsi"
              placeholder="Tulis Deskripsi"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>

  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    ToastificationContent,
    vSelect,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      selectedRetention: [],
      Subjek: '',
      to: '',
      note: '',
      files: [],
      Lokasi: '',
      Deskripsi: '',
      Kategori: '',
      selectedKategori: [],
      Waktu: null,
      Deadline: null,
      ReceivedDate: null,
      tags: [],
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'perjanjian-bisnis', text: 'Perjanjian Bisnis' },
        { value: 'perjanjian-organisasi', text: 'Perjanjian Organisasi' },
        { value: 'surat-tanah', text: 'Surat Tanah' },
        { value: 'surat-kendaraan', text: 'Surat Kendaraan' },
        { value: 'sk-pengurus', text: 'SK Pengurus' },
        { value: 'sk-manajemen', text: 'SK Manajemen' },
        { value: 'berita-acara', text: 'Berita Acara' },
        { value: 'surat-legal', text: 'Surat Legal' },
        { value: 'sertifikasi', text: 'Sertifikasi' },
        { value: 'sertifikat-penghargaan', text: 'Sertifikat Penghargaan' },
      ],
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addDispo()
        }
      })
    },

    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Archive',
        },
      })
      this.files = data
    },

    async addDispo() {
      await axios
        .post('siap/archive/create', {
          cat: this.Kategori.value,
          mail_no: this.NoSurat,
          title: this.Subjek,
          desc: this.Deskripsi,
          note: this.note,
          location: this.Lokasi,
          received_date: dayjs(this.ReceivedDate).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          start_date: dayjs(this.Waktu).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          deadline_date: dayjs(this.Deadline).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          status: 'Open',
          file: this.files.data,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-surat-keluar/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  .avatar {
    width: 80px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}

.warning-right {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}
</style>
